import { MESSAGE_DIRECTION } from '@whatsper/texterchat-common';
import classNames from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getMessengerPostData } from '../helpers';
import styles from '../MessengerMessage.module.scss';
const statusTypeToLabel = {
    added_photos: 'type_photo',
    added_video: 'type_video',
    app_created_story: 'type_published_story',
    approved_friend: 'type_approved_friend',
    created_event: 'type_event',
    created_group: 'type_group',
    created_note: 'type_note',
    mobile_status_update: 'type_status_update',
    published_story: 'type_published_story',
    shared_story: 'type_shared_story',
    tagged_in_photo: 'type_tagged_in_photo',
    wall_post: 'type_post_on_wall',
};
const MessengerMessageLabel = ({ message, direction }) => {
    const { t } = useTranslation();
    const post = getMessengerPostData(message);
    return (React.createElement("div", { className: classNames(styles.messageType, {
            [styles.outgoing]: direction === MESSAGE_DIRECTION.OUT,
        }) },
        message.feedComment &&
            ((post === null || post === void 0 ? void 0 : post.status_type) ? (React.createElement(Trans, { i18nKey: "comment_on", values: { type: t(statusTypeToLabel[post.status_type]) } })) : (React.createElement(Trans, { i18nKey: "comment" }))),
        message.feedReaction &&
            ((post === null || post === void 0 ? void 0 : post.status_type) ? (React.createElement(Trans, { i18nKey: "reacted_on", values: { type: t(statusTypeToLabel[post.status_type]) } })) : (React.createElement(Trans, { i18nKey: "reaction" })))));
};
export default MessengerMessageLabel;
