/** Get access to the post data outside of their respected components */
export const getMessengerPostData = (message) => {
    let post;
    if (message.feedComment)
        post = message.feedComment.post;
    if (message.feedReaction)
        post = message.feedReaction.post;
    if (!post) {
        return null;
    }
    return post;
};
