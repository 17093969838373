import React from 'react';
import { Messenger } from '@whatsper/texterchat-common';
import angryEmiticon from '../../../../../../../assets/icons/facebook/facebook-angry.png';
import careEmiticon from '../../../../../../../assets/icons/facebook/facebook-care.png';
import hahaEmiticon from '../../../../../../../assets/icons/facebook/facebook-haha.png';
import likeEmiticon from '../../../../../../../assets/icons/facebook/facebook-like.png';
import loveEmiticon from '../../../../../../../assets/icons/facebook/facebook-love.png';
import sadEmiticon from '../../../../../../../assets/icons/facebook/facebook-sad.png';
import wowEmiticon from '../../../../../../../assets/icons/facebook/facebook-wow.png';
import styles from '../MessengerMessage.module.scss';
const reactionToIconMap = {
    [Messenger.Feed.ReactionType.like]: likeEmiticon,
    [Messenger.Feed.ReactionType.love]: loveEmiticon,
    [Messenger.Feed.ReactionType.care]: careEmiticon,
    [Messenger.Feed.ReactionType.haha]: hahaEmiticon,
    [Messenger.Feed.ReactionType.wow]: wowEmiticon,
    [Messenger.Feed.ReactionType.sad]: sadEmiticon,
    [Messenger.Feed.ReactionType.angry]: angryEmiticon,
};
const FeedReaction = ({ feedReaction }) => {
    return (React.createElement("div", { className: styles.messageContainer },
        React.createElement("img", { src: reactionToIconMap[feedReaction.type], alt: "reaction", className: styles.reactionIcon })));
};
export default FeedReaction;
