import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { isContactsMessage, isMediaMessage, isRegularMessage, isTextMessage, MEDIA_TYPE, MESSAGE_DIRECTION, } from '@whatsper/texterchat-common';
import classNames from 'classnames';
import { isAdMessage } from '../../../helpers';
import DocumentPostback from '../MessagesTypes/MessagePostback/PostbackTypes/DocumentPostback';
import ImagePostback from '../MessagesTypes/MessagePostback/PostbackTypes/ImagePostback';
import TextPostback from '../MessagesTypes/MessagePostback/PostbackTypes/TextPostback';
import ContactPostback from '../MessagesTypes/MessagePostback/PostbackTypes/ContactPostback';
import VideoPostback from '../MessagesTypes/MessagePostback/PostbackTypes/VideoPostback';
import AudioPostback from '../MessagesTypes/MessagePostback/PostbackTypes/AudioPostback';
import AdPostback from '../MessagesTypes/MessagePostback/PostbackTypes/AdPostback';
import StickerPostback from '../MessagesTypes/MessagePostback/PostbackTypes/StickerPostback';
import SpecialPostback from '../MessagesTypes/MessagePostback/PostbackTypes/SpecialPostback';
import s from './Reply.module.scss';
const Reply = ({ activeChat, activeReplyMessage, clearReplyMessage, color }) => {
    const incoming = isRegularMessage(activeReplyMessage) && activeReplyMessage.direction === MESSAGE_DIRECTION.IN;
    const title = incoming ? activeChat.title : 'You';
    const content = () => {
        if (isMediaMessage(activeReplyMessage) && activeReplyMessage.media) {
            if ([MEDIA_TYPE.DOC].includes(activeReplyMessage.media[0].mediaType)) {
                return React.createElement(DocumentPostback, { context: activeReplyMessage, name: title, incoming: incoming, color: color, type: "reply" });
            }
            if ([MEDIA_TYPE.IMAGE].includes(activeReplyMessage.media[0].mediaType)) {
                return React.createElement(ImagePostback, { context: activeReplyMessage, name: title, incoming: incoming, color: color, type: "reply" });
            }
            if ([MEDIA_TYPE.VIDEO].includes(activeReplyMessage.media[0].mediaType)) {
                return React.createElement(VideoPostback, { context: activeReplyMessage, name: title, incoming: incoming, color: color, type: "reply" });
            }
            if ([MEDIA_TYPE.AUDIO].includes(activeReplyMessage.media[0].mediaType)) {
                return React.createElement(AudioPostback, { context: activeReplyMessage, name: title, incoming: incoming, type: "reply" });
            }
            if ([MEDIA_TYPE.STICKER].includes(activeReplyMessage.media[0].mediaType)) {
                return React.createElement(StickerPostback, { context: activeReplyMessage, name: title, incoming: incoming, type: "reply" });
            }
        }
        if (isAdMessage(activeReplyMessage)) {
            return React.createElement(AdPostback, { context: activeReplyMessage, name: title, incoming: incoming, type: "reply" });
        }
        if (isTextMessage(activeReplyMessage) && !isAdMessage(activeReplyMessage) && activeReplyMessage.text) {
            return React.createElement(TextPostback, { context: activeReplyMessage, name: title, incoming: incoming, type: "reply" });
        }
        if (isContactsMessage(activeReplyMessage)) {
            return React.createElement(ContactPostback, { context: activeReplyMessage, name: title, type: "reply" });
        }
        if (isRegularMessage(activeReplyMessage) && activeReplyMessage.special) {
            return React.createElement(SpecialPostback, { context: activeReplyMessage, name: title, incoming: incoming, type: "reply" });
        }
        return React.createElement("div", null);
    };
    return (React.createElement("div", { className: classNames(s.replyMessage, { [s.incoming]: incoming }) },
        React.createElement("div", { className: s.replyMessageContainer },
            React.createElement("div", { className: s.replyMessageBody }, content())),
        React.createElement(CloseOutlined, { className: s.closeReplyButton, onClick: clearReplyMessage })));
};
export default Reply;
