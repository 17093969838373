var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction, computed, makeObservable } from "mobx";
import { isValidPhoneNumber } from 'libphonenumber-js';
import _omit from 'lodash/omit';
import { API_PATH, WIZARD_STEPS, CHAT_STATUS, RICH_TEMPLATE_HEADER_TYPE } from "../../constants";
import { getAxios } from '../../backend';
import { EventName, TEMPLATE_HEADER_TYPE, Permissions } from '@whatsper/texterchat-common';
import { lowercaseKeys, trimKeys } from '../../helpers';
import { has4ConsecutiveSpaces, hasNewLine } from '../../helpers/string';
import showBulkProgressNotification from './bulkProgress';
class WizardStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "wizardStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: WIZARD_STEPS.ACCOUNT_STEP
        });
        Object.defineProperty(this, "chosenTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "uploadedFile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "uploadedImageOrFileStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "fileUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "richHeader", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "termsAgreement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "exampleTemplates", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "exampleTemplatesStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "whatsAppTaxes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0.027
        });
        Object.defineProperty(this, "clientsAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "limitError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(this, "loadingProgress", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "currentAmountOfLoadingMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "isWizardOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "unsubscribedChats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "unsubscribedChatsAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "bulkCrmData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "bulkIdsHideProgress", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable.array()
        });
        /** Enable schedule */
        Object.defineProperty(this, "scheduled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        /** Unix timestamp of datetime sending scheduled on */
        Object.defineProperty(this, "scheduledOn", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "numberIsValid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (phoneNumber = "") => {
                const { defaultCountry } = this.rootStore;
                return phoneNumber.trim().length && isValidPhoneNumber(phoneNumber, defaultCountry);
            }
        });
        Object.defineProperty(this, "setScheduled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (scheduled) => {
                this.scheduled = scheduled;
            }
        });
        Object.defineProperty(this, "setScheduledOn", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (scheduledOn) => {
                this.scheduledOn = scheduledOn;
            }
        });
        Object.defineProperty(this, "setWizardIsOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.isWizardOpen = !this.isWizardOpen;
            }
        });
        Object.defineProperty(this, "changeWizardStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (modifier) => {
                this.wizardStep += modifier;
            }
        });
        Object.defineProperty(this, "setWizardStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (step) => {
                this.wizardStep = step;
            }
        });
        Object.defineProperty(this, "setTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chosenTemplate) => {
                this.chosenTemplate = chosenTemplate;
            }
        });
        Object.defineProperty(this, "setTermsAgreement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.termsAgreement = !this.termsAgreement;
            }
        });
        Object.defineProperty(this, "setUploadedFile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (fileInfo, parsedData, extraData) => {
                this.uploadedFile = Object.assign(Object.assign(Object.assign({}, fileInfo), extraData), { name: fileInfo.name, data: parsedData });
            }
        });
        Object.defineProperty(this, "setExampleTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (file) => __awaiter(this, void 0, void 0, function* () {
                let previewList = [];
                file.forEach(file => {
                    var _a;
                    let text = ((_a = this.chosenTemplate) === null || _a === void 0 ? void 0 : _a.text) || '', number = '';
                    let index = 1;
                    for (const [key, value] of Object.entries(file)) {
                        if (key.toLowerCase() !== "phone" && key.toLowerCase() !== "header" && key.toLowerCase() !== "טלפון" && !key.toLowerCase().includes("crmdata")) {
                            text = text.replace("{{" + index + "}}", value);
                            index++;
                        }
                        else if (key.toLowerCase() === "phone") {
                            number = value;
                        }
                    }
                    previewList.push({ text, number });
                });
                this.exampleTemplates = previewList;
            })
        });
        Object.defineProperty(this, "setClientsAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.clientsAmount = this.uploadedFile ? this.uploadedFile.data.length : 0;
            }
        });
        Object.defineProperty(this, "sendCsvTemplates", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (accountId) => __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                if (!this.chosenTemplate) {
                    console.error('No template chosen');
                    throw new Error('No template chosen');
                }
                const templateMessage = {
                    templateId: this.chosenTemplate.obj.name,
                    text: this.chosenTemplate.text,
                    localization: this.chosenTemplate.language,
                    chatStatus: CHAT_STATUS.BULK,
                    from: accountId,
                    clients: (_a = this.uploadedFile) === null || _a === void 0 ? void 0 : _a.data,
                    sendingAgent: (_b = this.rootStore.loginStore.userDetails) === null || _b === void 0 ? void 0 : _b._id,
                    scheduled: this.scheduled ? this.scheduledOn : undefined,
                };
                templateMessage.replyAction = yield this.rootStore.templateMessagesStore.createTemplateReplyAction();
                if (this.richHeader) {
                    if (((_c = this.chosenTemplate.header) === null || _c === void 0 ? void 0 : _c.type) && [TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.DOCUMENT, TEMPLATE_HEADER_TYPE.VIDEO].includes(this.chosenTemplate.header.type)) {
                        const fileUrl = yield this.rootStore.files.uploadFileAndShare(this.richHeader.file, this.rootStore.files.getAgentUploadDirectory()).catch((e) => {
                            console.error('Failed to upload file');
                            throw new Error('Failed to upload file');
                        });
                        templateMessage.header = {
                            type: this.chosenTemplate.header.type,
                            url: fileUrl,
                            filename: TEMPLATE_HEADER_TYPE.DOCUMENT === this.chosenTemplate.header.type ? this.richHeader.file.name : void 0,
                        };
                    }
                    else if (templateMessage.header.type === TEMPLATE_HEADER_TYPE.TEXT) {
                        templateMessage.header = {
                            type: TEMPLATE_HEADER_TYPE.TEXT,
                            text: this.richHeader.text,
                        };
                    }
                }
                yield getAxios()
                    .then((axios) => {
                    return axios.post(`/templates/bulk`, templateMessage);
                })
                    .then(() => {
                    this.resetStore();
                    if (templateMessage.scheduled) {
                        this.rootStore.bulkReports.fetchScheduledReports();
                    }
                })
                    .catch((e) => {
                    console.error('Failed to send templates', e);
                    throw new Error('Failed to send templates');
                });
            })
        });
        Object.defineProperty(this, "getUnsubscribedChats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _d;
                const filterPhoneNumbers = (_d = this.uploadedFile) === null || _d === void 0 ? void 0 : _d.data.map(r => lowercaseKeys(r)).map((client) => client.phone);
                const request = { filterPhoneNumbers };
                try {
                    this.rootStore.chatsStore.getUnsubscribedChats(request).then((data) => {
                        if (!data)
                            return;
                        const { chats, totalFound } = data;
                        runInAction(() => {
                            this.unsubscribedChats = chats;
                            this.unsubscribedChatsAmount = totalFound;
                        });
                    });
                }
                catch (error) {
                    console.error("Error setting unsubscribed chats", error);
                }
                return [];
            })
        });
        Object.defineProperty(this, "updateTemplateLimit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (newCounter) => __awaiter(this, void 0, void 0, function* () {
                var _e, _f;
                let request = {
                    templatesAmount: 1
                };
                if (this.uploadedFile && !newCounter && ((_e = this.rootStore.generalSettings.inbox.template_limit) === null || _e === void 0 ? void 0 : _e.counter)) {
                    request.templatesAmount = this.rootStore.generalSettings.inbox.template_limit.counter + this.uploadedFile.data.length;
                }
                else if (newCounter && ((_f = this.uploadedFile) === null || _f === void 0 ? void 0 : _f.data)) {
                    request.templatesAmount = this.uploadedFile.data.length;
                }
                return yield getAxios()
                    .then((axios) => {
                    return axios.post(`${API_PATH.TEMPLATES}/update-limit`, request);
                })
                    .then(resp => this.rootStore.generalSettings.inbox.template_limit = resp.data.template_limit)
                    .catch(e => console.error(e.message));
            })
        });
        Object.defineProperty(this, "resetStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (resetStep = true) => {
                if (resetStep) {
                    this.wizardStep = WIZARD_STEPS.ACCOUNT_STEP;
                }
                this.chosenTemplate = null;
                this.uploadedFile = null;
                this.uploadedImageOrFileStep = null;
                this.fileUrl = null;
                this.richHeader = null;
                this.termsAgreement = false;
                this.exampleTemplates = [];
                this.exampleTemplatesStep = 0;
                this.clientsAmount = 0;
                this.loadingProgress = 0;
                this.currentAmountOfLoadingMessages = 0;
                this.scheduled = false;
                this.rootStore.templateMessagesStore.setStoreToDefault();
            }
        });
        Object.defineProperty(this, "setLoadingProgress", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (loadingProgress) => {
                this.loadingProgress = loadingProgress;
            }
        });
        Object.defineProperty(this, "setCurrentAmountOfLoadingMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.currentAmountOfLoadingMessages = this.currentAmountOfLoadingMessages + 1;
            }
        });
        Object.defineProperty(this, "onImageFileSelected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (file) => __awaiter(this, void 0, void 0, function* () {
                this.uploadedImageOrFileStep = file[0];
            })
        });
        Object.defineProperty(this, "onFileSelected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (files) => __awaiter(this, void 0, void 0, function* () {
                const reader = new FileReader();
                reader.onload = (e) => __awaiter(this, void 0, void 0, function* () {
                    var _g, _h, _j;
                    const text = (((_g = e.target) === null || _g === void 0 ? void 0 : _g.result) || '').toString();
                    let lines = text.split(/\r?\n/);
                    let result = [];
                    let headers = lines[0].split(",").map((line) => line.trim());
                    for (let i = 1; i < lines.length; i++) {
                        let obj = {};
                        let currentline = lines[i].split(",");
                        for (let j = 0; j < headers.length; j++) {
                            if (currentline[j] && currentline[j].length > 0) {
                                if (headers[j].toLowerCase() !== "header" && !headers[j].toLowerCase().includes("crmdata")) {
                                    obj[headers[j]] = currentline[j];
                                }
                                else if (headers[j].toLowerCase().includes("crmdata")) {
                                    obj.crmData = Object.assign(Object.assign({}, obj.crmData), { [headers[j].split("=")[1]]: currentline[j] });
                                }
                                else if (headers[j].toLowerCase() === "header" && currentline[j].trim() !== "") {
                                    let richHeader = {};
                                    try {
                                        richHeader = {
                                            type: RICH_TEMPLATE_HEADER_TYPE.IMAGE,
                                            url: currentline[j]
                                        };
                                    }
                                    catch (_) {
                                        richHeader = {
                                            type: RICH_TEMPLATE_HEADER_TYPE.TEXT,
                                            text: currentline[j]
                                        };
                                    }
                                    if (richHeader.type !== ((_j = (_h = this.chosenTemplate) === null || _h === void 0 ? void 0 : _h.header) === null || _j === void 0 ? void 0 : _j.type)) {
                                        this.onFilesError("wrongHeader");
                                    }
                                    else
                                        this.richHeader = richHeader;
                                }
                            }
                        }
                        if (Object.keys(obj).length > 0) {
                            result.push(obj);
                        }
                    }
                    const { error, errorMessage, errorValue, warning, warningMessage, warningValue, } = this.checkFileCorrect(result);
                    if (!error) {
                        this.setUploadedFile(files[0], result, { warning, warningMessage, warningValue });
                        this.setExampleTemplate(result);
                    }
                    else {
                        this.setUploadedFile({ error, errorMessage, errorValue });
                    }
                });
                reader.readAsText(files[0]);
            })
        });
        Object.defineProperty(this, "checkFileCorrect", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (result) => {
                var _a, _b;
                const testKeys = Object.keys(result[0]);
                const tempCount = (((_a = this.chosenTemplate) === null || _a === void 0 ? void 0 : _a.text.match(/{{(.*?)}}/g)) || []).length;
                let csvCount = 0;
                /* 1. Check if limit has been reached */
                const limit = (_b = this.rootStore.generalSettings.inbox.template_limit) === null || _b === void 0 ? void 0 : _b.limit;
                const sent = this.rootStore.templateMessagesStore.templateTierLimitCount;
                // const remaining = limit - sent;
                // Temporarily disabled because someone (customer? Guy?) claims that limit is wrong
                // if (result.length > remaining) {
                //     return {
                //         error: true,
                //         errorMessage: 'sendLimitReached',
                //         errorValue: { limit, remaining },
                //     };
                // }
                /* 2. Check for correct number of params */
                for (let i = 0; i < testKeys.length; i++)
                    if (testKeys[i].toLowerCase() !== "phone" &&
                        !testKeys[i].toLowerCase().startsWith("header") &&
                        !testKeys[i].toLowerCase().startsWith("crmdata")) {
                        csvCount += 1;
                    }
                if (csvCount !== tempCount) {
                    return { error: true, errorMessage: "wrongParamsAmount" };
                }
                /* 3 Check for correct header */
                if (testKeys.some(key => !key.toLowerCase())) {
                    return { error: true, errorMessage: "wrongHeaderFormat" };
                }
                /* 4. Check for correct phone format */
                const incorrectPhoneFormatsCount = result
                    .map((r) => lowercaseKeys(r))
                    .map((r) => trimKeys(r))
                    .filter(({ phone }) => !this.numberIsValid(phone));
                if (incorrectPhoneFormatsCount.length > 0) {
                    return {
                        warning: true,
                        warningMessage: "wrongPhoneFormat",
                        warningValue: { total: result.length, invalid: incorrectPhoneFormatsCount.length },
                    };
                }
                /* 5. Check for no extra characters in params (spaces, newlines) */
                const hasExtraSpaceOrNewline = result
                    .map((r) => lowercaseKeys(r))
                    .map((r) => trimKeys(r))
                    .map((r) => _omit(r, ["phone"]))
                    .some((params) => {
                    return Object.values(params).some((param) => has4ConsecutiveSpaces(param) || hasNewLine(param));
                });
                if (hasExtraSpaceOrNewline) {
                    return { error: true, errorMessage: "extraSpaceOrNewline" };
                }
                /* File passed */
                return { error: false };
            }
        });
        Object.defineProperty(this, "onFilesError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (error) => {
                this.setUploadedFile({ error: true, errorMessage: error });
            }
        });
        Object.defineProperty(this, "onImageOrFileStepLoad", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (files) => __awaiter(this, void 0, void 0, function* () {
                var _k;
                if (!files || !files.length)
                    return;
                const fileTypesMap = {
                    'image': TEMPLATE_HEADER_TYPE.IMAGE,
                    'video': TEMPLATE_HEADER_TYPE.VIDEO,
                };
                const file = files[0];
                let type = fileTypesMap[(_k = files[0].type.split('/')) === null || _k === void 0 ? void 0 : _k[0]];
                if (!type) {
                    type = TEMPLATE_HEADER_TYPE.DOCUMENT;
                }
                try {
                    runInAction(() => {
                        this.uploadedImageOrFileStep = file;
                        this.richHeader = { type, file };
                    });
                }
                catch (e) {
                    runInAction(() => {
                        this.richHeader = { error: "failedLoading" };
                    });
                }
            })
        });
        Object.defineProperty(this, "onImageLoadFail", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (error, files) => {
                this.richHeader = {
                    error: error
                };
            }
        });
        Object.defineProperty(this, "stopBulkSendProcess", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (bulkId) => __awaiter(this, void 0, void 0, function* () {
                yield getAxios()
                    .then((axios) => {
                    return axios.post(`/templates/bulk/${bulkId}/stop`);
                })
                    .catch((e) => console.error(e));
            })
        });
        Object.defineProperty(this, "pauseBulkSendProcess", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (bulkId) => __awaiter(this, void 0, void 0, function* () {
                yield getAxios()
                    .then((axios) => {
                    return axios.post(`/templates/bulk/${bulkId}/pause`);
                })
                    .catch((e) => console.error(e));
            })
        });
        Object.defineProperty(this, "resumeBulkSendProcess", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (bulkId) => __awaiter(this, void 0, void 0, function* () {
                yield getAxios()
                    .then((axios) => {
                    return axios.post(`/templates/bulk/${bulkId}/resume`);
                })
                    .catch((e) => console.error(e));
            })
        });
        Object.defineProperty(this, "updateExampleTemplatesStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (step) => {
                this.exampleTemplatesStep = step;
            }
        });
        Object.defineProperty(this, "showLogNotification", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (bulkId, log) => {
                if (!this.bulkIdsHideProgress.includes(bulkId) ||
                    this.rootStore.agentsStore.hasPermissions([Permissions.Permission.messages_bulk_send])) {
                    showBulkProgressNotification({
                        bulkId,
                        log,
                        onStop: this.stopBulkSendProcess,
                        onResume: this.resumeBulkSendProcess,
                        onPause: this.pauseBulkSendProcess,
                        onClose: () => {
                            runInAction(() => {
                                this.bulkIdsHideProgress.push(bulkId);
                            });
                        },
                    });
                }
            }
        });
        Object.defineProperty(this, "onBulkStartedEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data) => {
                this.showLogNotification(data._id, Object.assign({ sent: 0, failed: 0, skipped: 0 }, data));
            }
        });
        Object.defineProperty(this, "onBulkProgressEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data) => {
                this.showLogNotification(data._id, Object.assign(Object.assign({}, data), { paused: data.paused ? true : false }));
            }
        });
        makeObservable(this);
        this.rootStore = rootStore;
        this.rootStore.events
            .addListener(EventName.bulk_send_started, (data) => this.onBulkStartedEvent(data))
            .addListener(EventName.bulk_send_progress, (data) => this.onBulkProgressEvent(data));
    }
    get templateHasFile() {
        var _a, _b;
        if ((_a = this.chosenTemplate) === null || _a === void 0 ? void 0 : _a.header) {
            return [TEMPLATE_HEADER_TYPE.DOCUMENT, TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.VIDEO].includes((_b = this.chosenTemplate.header) === null || _b === void 0 ? void 0 : _b.type);
        }
        return false;
    }
}
__decorate([
    observable
], WizardStore.prototype, "wizardStep", void 0);
__decorate([
    observable
], WizardStore.prototype, "chosenTemplate", void 0);
__decorate([
    observable
], WizardStore.prototype, "uploadedFile", void 0);
__decorate([
    observable
], WizardStore.prototype, "uploadedImageOrFileStep", void 0);
__decorate([
    observable
], WizardStore.prototype, "fileUrl", void 0);
__decorate([
    observable
], WizardStore.prototype, "richHeader", void 0);
__decorate([
    observable
], WizardStore.prototype, "termsAgreement", void 0);
__decorate([
    observable
], WizardStore.prototype, "exampleTemplates", void 0);
__decorate([
    observable
], WizardStore.prototype, "exampleTemplatesStep", void 0);
__decorate([
    observable
], WizardStore.prototype, "whatsAppTaxes", void 0);
__decorate([
    observable
], WizardStore.prototype, "clientsAmount", void 0);
__decorate([
    observable
], WizardStore.prototype, "limitError", void 0);
__decorate([
    observable
], WizardStore.prototype, "loadingProgress", void 0);
__decorate([
    observable
], WizardStore.prototype, "currentAmountOfLoadingMessages", void 0);
__decorate([
    observable
], WizardStore.prototype, "isWizardOpen", void 0);
__decorate([
    observable
], WizardStore.prototype, "unsubscribedChats", void 0);
__decorate([
    observable
], WizardStore.prototype, "unsubscribedChatsAmount", void 0);
__decorate([
    observable
], WizardStore.prototype, "bulkCrmData", void 0);
__decorate([
    observable
], WizardStore.prototype, "scheduled", void 0);
__decorate([
    observable
], WizardStore.prototype, "scheduledOn", void 0);
__decorate([
    computed
], WizardStore.prototype, "templateHasFile", null);
__decorate([
    action
], WizardStore.prototype, "setScheduled", void 0);
__decorate([
    action
], WizardStore.prototype, "setScheduledOn", void 0);
__decorate([
    action
], WizardStore.prototype, "setWizardIsOpen", void 0);
__decorate([
    action
], WizardStore.prototype, "changeWizardStep", void 0);
__decorate([
    action
], WizardStore.prototype, "setWizardStep", void 0);
__decorate([
    action
], WizardStore.prototype, "setTemplate", void 0);
__decorate([
    action
], WizardStore.prototype, "setTermsAgreement", void 0);
__decorate([
    action
], WizardStore.prototype, "setUploadedFile", void 0);
__decorate([
    action
], WizardStore.prototype, "setExampleTemplate", void 0);
__decorate([
    action
], WizardStore.prototype, "setClientsAmount", void 0);
__decorate([
    action
], WizardStore.prototype, "getUnsubscribedChats", void 0);
__decorate([
    action
], WizardStore.prototype, "resetStore", void 0);
__decorate([
    action
], WizardStore.prototype, "setLoadingProgress", void 0);
__decorate([
    action
], WizardStore.prototype, "setCurrentAmountOfLoadingMessages", void 0);
__decorate([
    action
], WizardStore.prototype, "onImageFileSelected", void 0);
__decorate([
    action
], WizardStore.prototype, "onImageOrFileStepLoad", void 0);
__decorate([
    action
], WizardStore.prototype, "onImageLoadFail", void 0);
__decorate([
    action
], WizardStore.prototype, "stopBulkSendProcess", void 0);
__decorate([
    action
], WizardStore.prototype, "pauseBulkSendProcess", void 0);
__decorate([
    action
], WizardStore.prototype, "resumeBulkSendProcess", void 0);
__decorate([
    action
], WizardStore.prototype, "updateExampleTemplatesStep", void 0);
export default WizardStore;
