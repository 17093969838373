var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Facebook } from '@whatsper/texterchat-common';
import React, { useEffect, useState } from 'react';
import { isSafari } from '../../../../../../../../helpers';
import { getFilePreviewUrl, getFileUrl } from '../../../../../../../../store/FilesStore';
import { BsChatRightTextFill } from 'react-icons/bs';
import { Trans } from 'react-i18next';
import s from './AdMessage.module.scss';
const AdMessage = ({ referral, shareFile, embedAd }) => {
    const [url, setUrl] = useState(undefined);
    useEffect(() => {
        if (!embedAd)
            return;
        if (referral.media_type === Facebook.ReferralMediaType.image) {
            setUrl(getFilePreviewUrl(referral.image));
        }
        if (referral.media_type === Facebook.ReferralMediaType.video && shareFile) {
            const getVideoUrl = (fileId) => __awaiter(void 0, void 0, void 0, function* () {
                if (fileId) {
                    if (isSafari() || window.ReactNativeWebView) {
                        return shareFile(fileId);
                    }
                    return getFileUrl(fileId);
                }
            });
            getVideoUrl(referral.video).then((url) => {
                if (isSafari()) {
                    setUrl(url + '#t=0.1');
                }
                else {
                    setUrl(url);
                }
            });
        }
    }, [embedAd]);
    const renderAd = () => {
        if (referral.media_type === Facebook.ReferralMediaType.image) {
            return React.createElement("img", { src: url, alt: "ad" });
        }
        if (referral.media_type === Facebook.ReferralMediaType.video) {
            return React.createElement("video", { src: url, controls: true });
        }
    };
    const onClick = () => {
        window.open(referral.source_url, '_blank', 'noopener noreferrer');
    };
    const domain = new URL(referral.source_url);
    return (React.createElement("div", { className: s.container },
        React.createElement("div", { className: s.adMessageInfoBox },
            React.createElement(BsChatRightTextFill, null),
            React.createElement(Trans, { i18nKey: "messageSentViaAd" })),
        embedAd && (React.createElement("div", { className: s.container, onClick: onClick },
            React.createElement(React.Fragment, null, renderAd()),
            React.createElement("span", { className: s.hostname, title: referral.source_url }, domain.hostname)))));
};
export default AdMessage;
