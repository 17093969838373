var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Messenger, MessagingChannel, MESSAGE_DIRECTION } from '@whatsper/texterchat-common';
import { isRegularMessage, isSpecialMessage } from '@whatsper/texterchat-common';
import { makeObservable, observable, action } from 'mobx';
const day = 24 * 60 * 60 * 1000;
const week = 7 * day;
export class MessengerModule {
    constructor(activeChatStore) {
        Object.defineProperty(this, "activeChatStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.activeChatStore = activeChatStore;
    }
    isMessengerChannel(channel) {
        return channel === MessagingChannel.messenger;
    }
    /**
     * Checks if the chat is in private reply mode -
     * 1. the channel is messenger
     * 2. there's no direct message yet
     * 3. there's only one outgoing message
     * 4. the outgoing message was sent within 7 days
     * 5. if there's an outgoing message, wait for the answer within 24hr
     * @param chat - The chat to check
     * @param messages - The messages of the chat
     * @returns true if the chat is in private reply mode, false otherwise
     */
    chatIsInPrivateReplyMode(chat, messages) {
        // 1. check if the channel is messenger
        if (!(chat === null || chat === void 0 ? void 0 : chat.channelInfo.name) || !this.isMessengerChannel(chat === null || chat === void 0 ? void 0 : chat.channelInfo.name))
            return false;
        // 2. If there's already a direct message, no need to check for direct reply logic
        if (this.hasAlreadyReceivedADirectMessage(messages))
            return false;
        // 3. only one message can be sent (check if there's no more than one outgoing message)
        const outgoingMessages = this.activeChatStore.messages.filter((message) => isRegularMessage(message) && message.direction === MESSAGE_DIRECTION.OUT);
        if (outgoingMessages.length > 1)
            return false;
        // 4. it can be sent only within 7 days since receiving
        if ((chat === null || chat === void 0 ? void 0 : chat.lastIncomingMessageTimestamp) &&
            Date.now() - chat.lastIncomingMessageTimestamp > week)
            return false;
        // 5. if there's an outgoing message, wait for the answer within 24hr
        const lastOutgoingMessage = this.activeChatStore.messages.find((message) => isRegularMessage(message) && message.direction === MESSAGE_DIRECTION.OUT);
        const lastIncomingMessage = this.activeChatStore.messages.find((message) => isRegularMessage(message) && message.direction === MESSAGE_DIRECTION.IN);
        if (!lastIncomingMessage || !lastOutgoingMessage)
            return false;
        if (lastIncomingMessage.timestamp <= lastOutgoingMessage.timestamp ||
            lastIncomingMessage.timestamp - lastOutgoingMessage.timestamp > day)
            return false;
        return true;
    }
    getMessageSpecial(chat, messages, activeReplyMessage) {
        var _a, _b, _c;
        let messageSpecial = undefined;
        if (this.shouldAddMessengerMessageTag(chat.channelInfo.name, chat.lastIncomingMessageTimestamp)) {
            messageSpecial = {
                messenger: {
                    tag: Messenger.Message.Tag.HUMAN_AGENT,
                },
            };
        }
        if (this.isMessengerChannel(chat.channelInfo.name) &&
            !this.hasAlreadyReceivedADirectMessage(messages)) {
            // get feedComment.id from the or comment reply, or from the last incoming message
            const feedCommentMessage = activeReplyMessage || messages.find((message) => isRegularMessage(message) && message.direction === MESSAGE_DIRECTION.IN);
            if (isRegularMessage(feedCommentMessage) && ((_b = (_a = feedCommentMessage.special) === null || _a === void 0 ? void 0 : _a.messenger) === null || _b === void 0 ? void 0 : _b.feedComment)) {
                messageSpecial = {
                    messenger: Object.assign(Object.assign({}, messageSpecial === null || messageSpecial === void 0 ? void 0 : messageSpecial.messenger), { replyTo: { comment_id: (_c = feedCommentMessage.special) === null || _c === void 0 ? void 0 : _c.messenger.feedComment.id } }),
                };
            }
        }
        return messageSpecial;
    }
    hasAlreadyReceivedADirectMessage(messages) {
        return messages.some((message) => isRegularMessage(message) && !isSpecialMessage(message) && message.direction === MESSAGE_DIRECTION.IN);
    }
    shouldAddMessengerMessageTag(channel, lastIncomingMessageTimestamp) {
        const now = Date.now();
        const sessionIsOlderThan24h = lastIncomingMessageTimestamp && now - lastIncomingMessageTimestamp > day;
        return this.isMessengerChannel(channel) && sessionIsOlderThan24h;
    }
}
__decorate([
    observable
], MessengerModule.prototype, "activeChatStore", void 0);
__decorate([
    action
], MessengerModule.prototype, "chatIsInPrivateReplyMode", null);
