import React from 'react';
import { MEDIA_TYPE } from '@whatsper/texterchat-common';
import StickerMessage from '../../StickerMessage';
import AudioMessage from '../../AudioMessage';
import DocumentMessage from '../../DocumentMessage';
import ImageMessage from '../../ImageMessage';
import VideoMessage from '../../VideoMessage';
const SingleMediaMessage = ({ media, message, imageMessages, color, renderMessageAsHTML = false, shareFile, }) => {
    const { _id, direction } = message;
    if ([MEDIA_TYPE.AUDIO].includes(media.mediaType)) {
        return React.createElement(AudioMessage, { media: media });
    }
    if ([MEDIA_TYPE.DOC].includes(media.mediaType)) {
        return (React.createElement(DocumentMessage, { message: message, shareFile: shareFile, showCaption: true, color: color, key: media.fileId, renderMessageAsHTML: renderMessageAsHTML }));
    }
    if (media.mediaType === MEDIA_TYPE.IMAGE) {
        return (React.createElement(ImageMessage, { messageId: _id, direction: direction, media: media, imageMessages: imageMessages, showCaption: true, renderMessageAsHTML: renderMessageAsHTML }));
    }
    if (media.mediaType === MEDIA_TYPE.VIDEO) {
        return (React.createElement(VideoMessage, { media: media, direction: direction, shareFile: shareFile, showCaption: true, renderMessageAsHTML: renderMessageAsHTML }));
    }
    if (media.mediaType === MEDIA_TYPE.STICKER) {
        return React.createElement(StickerMessage, { media: media });
    }
    return null;
};
export default SingleMediaMessage;
