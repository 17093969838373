import React from 'react';
import notification from 'antd/lib/notification';
import BulkProgressDisplay from './BulkProgressDisplay';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Tooltip } from 'antd';
import { Trans } from 'react-i18next';
import { getDirection } from '../../../helpers/functions';
const styles = {
    hideButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
    },
    icon: {
        width: 16,
        height: 16,
    },
};
const showBulkProgressNotification = ({ bulkId, log, onStop, onResume, onPause, onClose }) => {
    notification.open({
        key: `bulk_send__${bulkId}`,
        placement: 'topRight',
        message: React.createElement(Trans, { i18nKey: "bulkSendProgressTitle" }),
        description: (React.createElement(BulkProgressDisplay, { data: log, onStop: () => onStop(bulkId), onResume: () => onResume(bulkId), onPause: () => onPause(bulkId), messageStopped: React.createElement(Trans, { i18nKey: "bulkSendProgressStopped" }) })),
        duration: 0,
        style: { width: 460 },
        closeIcon: (React.createElement(Tooltip, { title: React.createElement(Trans, { i18nKey: "hideBulkSendProgress" }), placement: getDirection() === 'rtl' ? 'right' : 'left' },
            React.createElement("div", { style: styles.hideButton },
                React.createElement("span", null,
                    React.createElement(Trans, { i18nKey: "hide" })),
                React.createElement(AiOutlineArrowRight, { style: styles.icon })))),
        onClose,
    });
};
export default showBulkProgressNotification;
