import React from 'react';
import StoryMention from '../../../../SpecialMessage/messages/InstagramMessage/components/StoryMention';
import StoryReply from '../../../../SpecialMessage/messages/InstagramMessage/components/StoryReply';
import Comment from '../../../../SpecialMessage/messages/InstagramMessage/components/Comment';
const InstagramPostback = ({ message, direction }) => {
    return (React.createElement("div", null,
        message.storyMention && React.createElement(StoryMention, { storyMention: message.storyMention, direction: direction }),
        message.storyReply && React.createElement(StoryReply, { storyReply: message.storyReply }),
        message.comment && React.createElement(Comment, { comment: message.comment })));
};
export default InstagramPostback;
