import React from 'react';
import classNames from 'classnames';
import Icon, { FileFilled } from '@ant-design/icons';
import { isArray } from 'lodash';
import { MESSAGE_DIRECTION } from '@whatsper/texterchat-common';
import Document from '../../../../../../components/icons/document';
import styles from './DocumentPostback.module.scss';
const DocumentPostback = ({ context, name, type, incoming, color, invertChatColor }) => {
    const documentName = context.media[0].caption || context.media[0].filename;
    return (React.createElement("div", { className: classNames(styles.main, {
            [styles.reply]: type === 'reply',
        }) },
        React.createElement("div", { className: styles.info },
            React.createElement("p", { className: classNames(styles.name, {
                    [styles.incoming]: incoming,
                    [styles.invertColors]: !incoming && invertChatColor && type === 'message',
                }) }, context.direction === MESSAGE_DIRECTION.IN ? name : 'You'),
            React.createElement("div", { className: classNames(styles.text, {
                    [styles.reply]: type === 'reply',
                    [styles.invertColors]: !incoming && type === 'message',
                }) },
                React.createElement(Document, { color: incoming || type === 'reply' ? (isArray(color) ? color[0] : color) : '#ffffff', width: 24, height: 24 }),
                React.createElement("span", null, documentName || 'Document'))),
        React.createElement(Icon, { className: styles.fileIcon, component: FileFilled })));
};
export default DocumentPostback;
