import React from 'react';
import { MESSAGE_DIRECTION } from '@whatsper/texterchat-common';
import classNames from 'classnames';
import { FacebookPostback, InstagramPostback, MessengerPostback, WhatsappPostback } from './messages';
import styles from './SpecialPostback.module.scss';
const SpecialPostback = ({ context, name, type, incoming, invertChatColor }) => {
    var _a, _b, _c, _d;
    return (React.createElement("div", { className: classNames(styles.main, {
            [styles.reply]: type === 'reply',
        }) },
        React.createElement("p", { className: classNames(styles.name, {
                [styles.incoming]: incoming,
                [styles.invertColors]: !incoming && invertChatColor && type === 'message',
            }) }, context.direction === MESSAGE_DIRECTION.IN ? name : 'You'),
        React.createElement("div", { className: classNames(styles.content, {
                [styles.invertColors]: !incoming && invertChatColor && type === 'message',
            }) },
            ((_a = context.special) === null || _a === void 0 ? void 0 : _a.instagram) && (React.createElement(InstagramPostback, { message: context.special.instagram, direction: incoming ? MESSAGE_DIRECTION.IN : MESSAGE_DIRECTION.OUT })),
            ((_b = context.special) === null || _b === void 0 ? void 0 : _b.messenger) && React.createElement(MessengerPostback, { message: context.special.messenger }),
            ((_c = context.special) === null || _c === void 0 ? void 0 : _c.whatsapp) && React.createElement(WhatsappPostback, { message: context.special.whatsapp, text: context.text }),
            ((_d = context.special) === null || _d === void 0 ? void 0 : _d.facebook) && React.createElement(FacebookPostback, { message: context.special.facebook }))));
};
export default SpecialPostback;
