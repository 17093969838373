import React from 'react';
import MessengerMessageLabel from './components/MessengerMessageLabel';
import FeedComment from './components/FeedComment';
import FeedReaction from './components/FeedReaction';
import ViewOnFacebookPost from './components/ViewPostOnFacebook';
import styles from './MessengerMessage.module.scss';
const MessengerMessage = ({ message, direction }) => {
    return (React.createElement("div", { className: styles.messageContainer },
        React.createElement(MessengerMessageLabel, { message: message, direction: direction }),
        React.createElement(ViewOnFacebookPost, { message: message }),
        message.feedComment && React.createElement(FeedComment, { feedComment: message.feedComment }),
        message.feedReaction && React.createElement(FeedReaction, { feedReaction: message.feedReaction })));
};
export default MessengerMessage;
