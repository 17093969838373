import React from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getDirection } from '../../../helpers';
import './ChatInfoBlock.scss';
/**
 * ChatInfoBlock is component that shows extra information about chat, rendered at the bottom
 *
 * @param titleKey - key for message translation
 * @param components - additional components to modify the message
 * @returns
 */
const ChatInfoBlock = ({ titleKey, components }) => {
    const isRTL = getDirection() === 'rtl';
    return (React.createElement("div", { className: "info-block-container" },
        React.createElement("div", { className: "info-block-session" },
            React.createElement("div", { className: classnames('text-block', { rtl: isRTL }) },
                React.createElement(ExclamationCircleOutlined, { className: "info-icon" }),
                React.createElement("p", { className: "text" },
                    React.createElement(Trans, { i18nKey: titleKey, components: components }))))));
};
export default ChatInfoBlock;
