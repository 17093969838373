import React from 'react';
import { MEDIA_TYPE, MESSAGE_DIRECTION, } from '@whatsper/texterchat-common';
import classNames from 'classnames';
import SafeHTMLMarkdown from '../../../../Base/Utils/SafeHTMLMarkdown';
import { useStore } from '../../../../../store/rootStore';
import ImageMessage from '../../ImageMessage';
import VideoMessage from '../../VideoMessage';
import DocumentMessage from '../../DocumentMessage';
import AudioMessage from '../../AudioMessage';
import StickerMessage from '../../StickerMessage';
import styles from './MixedMediaMessage.module.scss';
const MixedMediaMessage = ({ message, additionalClassName, color, shareFile }) => {
    var _a;
    const caption = (_a = message.media.find((media) => media.caption)) === null || _a === void 0 ? void 0 : _a.caption;
    const mediaTypes = message.media.reduce((acc, media) => {
        if ([MEDIA_TYPE.IMAGE, MEDIA_TYPE.VIDEO, MEDIA_TYPE.STICKER, MEDIA_TYPE.AUDIO].includes(media.mediaType)) {
            acc.nonDocumentsMedia.push(media);
        }
        else {
            acc.documents.push(media);
        }
        return acc;
    }, { nonDocumentsMedia: [], documents: [] });
    const { indexedImageMessages } = useStore().activeChatStore;
    return (React.createElement("div", { className: classNames(styles.container, additionalClassName) },
        caption ? (React.createElement(SafeHTMLMarkdown, { message: caption, attributes: {
                dir: 'auto',
                className: classNames(styles.caption, 'markup-text-container', {
                    [styles.incoming]: message.direction === MESSAGE_DIRECTION.IN,
                }),
            } })) : null,
        mediaTypes.nonDocumentsMedia.length > 0 && (React.createElement("div", { style: { position: 'relative' } },
            React.createElement("div", { className: styles.sectionTitle },
                "Media (",
                mediaTypes.nonDocumentsMedia.length,
                ")",
                React.createElement("div", { className: styles.mediaGridWrapper }, mediaTypes.nonDocumentsMedia.map((media, index) => {
                    if (media.mediaType === MEDIA_TYPE.IMAGE) {
                        return (React.createElement(ImageMessage, { messageId: message._id, direction: message.direction, key: `${index}-${media.fileId}`, media: media, imageMessages: indexedImageMessages, className: styles.videoImageWrapper, size: "small", showCaption: false }));
                    }
                    if (media.mediaType === MEDIA_TYPE.VIDEO) {
                        return (React.createElement(VideoMessage, { key: `${index}-${media.fileId}`, media: media, direction: message.direction, shareFile: shareFile, className: styles.videoImageWrapper, size: "small", showCaption: false }));
                    }
                    if (media.mediaType === MEDIA_TYPE.AUDIO) {
                        return React.createElement(AudioMessage, { key: `${index}-${media.fileId}`, media: media });
                    }
                    if (media.mediaType === MEDIA_TYPE.STICKER) {
                        return React.createElement(StickerMessage, { media: media });
                    }
                }))))),
        mediaTypes.documents.length > 0 && (React.createElement("div", { className: styles.documentsWrapper },
            React.createElement("div", { className: classNames(styles.sectionTitle, {
                    [styles.incomingText]: message.direction === MESSAGE_DIRECTION.IN,
                }) },
                "Documents (",
                mediaTypes.documents.length,
                ")"),
            React.createElement("div", { className: styles.documentsGridWrapper }, mediaTypes.documents.map((media, index) => {
                return (React.createElement(DocumentMessage, { key: `${index}-${media.fileId}`, message: message, color: color, shareFile: shareFile, showCaption: false }));
            }))))));
};
export default MixedMediaMessage;
