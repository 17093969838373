var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from "react";
import { observer } from "mobx-react";
import classnames from "classnames";
import { CHAT_STATUS, isRegularMessage, isTextMessage, MessagingChannelFeature, Permissions } from "@whatsper/texterchat-common";
import { FcLock } from "react-icons/fc";
import { isArray } from "lodash";
import { message } from "antd";
import { Trans } from "react-i18next";
import AcceptChatBlock from "../AcceptChatBlock";
import ChatInfoBlock from "../ChatInfoBlock";
import ChatsList from "../ChatsList";
import MessagesList from "../MessagesList";
import Compose from "../Compose";
import UserInfo from "../UserInfo/UserInfo";
import NoActiveChatSelected from "../NoActiveChatSelected";
import QuickReplies from "../QuickReplies/ActivityBar";
import LoadingScreen from "../../LoadingScreen";
import defaultBackground from "../../../assets/themePresetsChannel/backgrounds/defaultBackground.jpeg";
import ReplyPreview from "../ReplyPreview";
import ScrollToUnreadButton from "../ScrollToUnreadButton";
import { INPUT_BLOCK } from "../../../constants";
import BlockedChatBar from "../BlockedChat/BlockedChatBar";
import DragAndDrop from "../DragAndDrop";
import SendLocationWindow from "../SendLocationWindow";
import { withStore } from "../../../store/rootStore";
import { MEDIA_TYPE } from "@whatsper/texterchat-common/constants";
import FilePreview from "../FilePreview";
import ErrorBoundary from "../../ErrorBoundary";
import DefaultTemplateReplyBar from "../DefaultTemplateBar";
import NoChatViewPermission from "../NoChatViewPermission";
import AudioRecordBar from "../AudioRecordBar";
import { ChatPaneName } from "../chatPaneName";
import { Portal, PortalName } from "../../portals";
import ChatSearch from "../ChatSearch";
import FilesMediaLinks from "../../MainPage/RightBar/modules/FilesMediaLinks";
import { RightSidebarModules } from "../../../store/NavigationStore";
import withIsMobile from "../../../helpers/hooks/HOC/withIsMobile";
import { checkFileMaxSize } from "./helpers";
import { fileEnsureUid } from "../../../helpers/file";
import styles from "./ChatWindow.module.scss";
/**
 * @todo THIS COMPONENT STILL VERY NEED DEEP REFACTORING !!!
 */
class Messenger extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "messagesList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "resetAndUpdateChatWindow", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (activeChat) => {
                const { activeChatStore, navigation } = this.props.store;
                this.setState({
                    quickReply: "",
                    composeText: "",
                    defaultTemplateText: "",
                    currentChatId: activeChat._id,
                });
                activeChatStore.toggleFilePreview(false);
                this.props.store.templateMessagesStore.setSelectedAccount({
                    id: activeChat.channelInfo.accountId,
                    name: activeChat.channelInfo.name,
                });
            }
        });
        Object.defineProperty(this, "supportsReply", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a, _b;
                const { activeChatStore, channels } = this.props.store;
                const adapter = activeChatStore.chat ? channels.getAccountAdapter({
                    id: (_a = activeChatStore.chat) === null || _a === void 0 ? void 0 : _a.channelInfo.accountId,
                    name: (_b = activeChatStore.chat) === null || _b === void 0 ? void 0 : _b.channelInfo.name,
                }) : null;
                return channels.hasFeatures([MessagingChannelFeature.outboundMessageQuote], adapter);
            }
        });
        Object.defineProperty(this, "sendReplyMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                const { activeReplyMessage, sendTextMessage } = this.props.store.activeChatStore;
                if (this.supportsReply()) {
                    const messageQuotedId = activeReplyMessage === null || activeReplyMessage === void 0 ? void 0 : activeReplyMessage.channelInfo.id;
                    sendTextMessage(text, messageQuotedId);
                }
                else {
                    if (isTextMessage(activeReplyMessage) && activeReplyMessage.text) {
                        text = `> ${activeReplyMessage.text}\n\n${text}`;
                    }
                    else if (isRegularMessage(activeReplyMessage)) {
                        text = `> ${activeReplyMessage.type}\n\n${text}`;
                    }
                    ;
                    sendTextMessage(text);
                }
            }
        });
        Object.defineProperty(this, "sendTextMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                const { activeReplyMessage, sendTextMessage } = this.props.store.activeChatStore;
                if (!activeReplyMessage) {
                    sendTextMessage(text);
                }
                else {
                    this.sendReplyMessage(text);
                }
            }
        });
        Object.defineProperty(this, "beforeUpload", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (files) => {
                var _a, _b;
                const { activeChatStore, channels } = this.props.store;
                const { chat: activeChat } = activeChatStore;
                if (!activeChat)
                    return false;
                const adapter = channels.getAccountAdapter({
                    id: (_a = activeChat.channelInfo) === null || _a === void 0 ? void 0 : _a.accountId,
                    name: (_b = activeChat.channelInfo) === null || _b === void 0 ? void 0 : _b.name,
                });
                const mediaLimits = adapter === null || adapter === void 0 ? void 0 : adapter.messageLimits.media;
                const checkStatuses = files.map((file) => {
                    const maxSizeError = checkFileMaxSize(file, mediaLimits);
                    if (maxSizeError) {
                        message.error(React.createElement(Trans, { i18nKey: maxSizeError.key, values: { size: maxSizeError.size } }));
                        return false;
                    }
                    return true;
                });
                return checkStatuses.every((check) => check);
            }
        });
        Object.defineProperty(this, "onFilesDrop", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rawFiles, caption = this.state.composeText) => {
                const files = Array.isArray(rawFiles) ? rawFiles : Array.from(rawFiles);
                if (files.every((file) => file.type)) {
                    const success = this.beforeUpload(files);
                    if (!success)
                        return;
                    const filesData = files.map((file) => {
                        let type = file.type.split("/")[0];
                        if (![MEDIA_TYPE.IMAGE, MEDIA_TYPE.VIDEO, "audio"].includes(type)) {
                            type = MEDIA_TYPE.DOC;
                        }
                        return { file: fileEnsureUid(file), type };
                    });
                    this.props.store.activeChatStore.setFilesData({ files: filesData, caption });
                    this.props.store.activeChatStore.toggleFilePreview(true);
                    this.onComposeTextChange("");
                }
            }
        });
        Object.defineProperty(this, "onQuickReplySelected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (reply) => {
                this.setState({ quickReply: reply.text });
            }
        });
        Object.defineProperty(this, "setAudioBarOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (audioBarOpen) => {
                this.setState({ audioBarOpen });
            }
        });
        Object.defineProperty(this, "sendAudioMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (file) => {
                this.props.store.activeChatStore.sendMediaMessage([{
                        file,
                        type: MEDIA_TYPE.AUDIO,
                    }]);
                this.setState({ audioBarOpen: false });
            }
        });
        Object.defineProperty(this, "handleBackgrounds", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { chat, theme } = this.props.store.activeChatStore;
                const color = isArray(theme.color) ? theme.color[0] : theme.color;
                if (chat) {
                    return `linear-gradient(${color}50, ${color}50), url(${defaultBackground})`;
                }
                ;
                return `url(${defaultBackground})`;
            }
        });
        Object.defineProperty(this, "handlePaste", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e, allowPaste) => {
                var _a;
                if (!allowPaste)
                    return;
                if ((_a = e.clipboardData.files) === null || _a === void 0 ? void 0 : _a.length) {
                    e.preventDefault();
                    this.onFilesDrop(e.clipboardData.files);
                }
            }
        });
        Object.defineProperty(this, "onComposeTextChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                this.setState({ composeText: text });
            }
        });
        Object.defineProperty(this, "scrollToUnread", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.messagesList) {
                    this.messagesList.scrollToUnread();
                }
            }
        });
        Object.defineProperty(this, "scrollToBottom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.messagesList) {
                    this.messagesList.scrollToBottom();
                }
            }
        });
        Object.defineProperty(this, "scrollToMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (messageId) => {
                if (this.messagesList) {
                    this.messagesList.scrollToMessage(messageId);
                }
            }
        });
        Object.defineProperty(this, "inputBlockToShow", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { activeChatStore, loginStore } = this.props.store, { chat: chat, sessionIsOver, filePreview, sendLocationCoords } = activeChatStore, { userDetails } = loginStore, acceptChatCondition = chat && (chat.status !== CHAT_STATUS.ASSIGNED || (chat.agent && (userDetails === null || userDetails === void 0 ? void 0 : userDetails.uid) !== chat.agent.uid));
                const { audioBarOpen } = this.state;
                if ((_a = chat === null || chat === void 0 ? void 0 : chat.blockedChat) === null || _a === void 0 ? void 0 : _a.blocked) {
                    return INPUT_BLOCK.BLOCKED;
                }
                else if (!acceptChatCondition && sessionIsOver) {
                    return INPUT_BLOCK.SESSION_OVER;
                }
                else if (!acceptChatCondition && activeChatStore.sendingDirectMessageIsDisabled) {
                    return INPUT_BLOCK.MESSENGER_PRIVATE_REPLY;
                }
                else if (!acceptChatCondition && chat && !sessionIsOver && !filePreview && !audioBarOpen && !sendLocationCoords) {
                    return INPUT_BLOCK.COMPOSE;
                }
                else if (audioBarOpen) {
                    return INPUT_BLOCK.AUDIO_RECORD;
                }
                else if (acceptChatCondition) {
                    return INPUT_BLOCK.ACCEPT;
                }
                return null;
            }
        });
        Object.defineProperty(this, "renderHelperScreens", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { chat, chatAccess, chatLoading } = this.props.store.activeChatStore;
                if (!chat) {
                    if (chatLoading) {
                        return (React.createElement("div", { className: styles.loadingWrapper },
                            React.createElement(LoadingScreen, null)));
                    }
                    if (!chatAccess || (chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.view)) {
                        return React.createElement(NoActiveChatSelected, { bottomBlock: true });
                    }
                    else {
                        return React.createElement(NoChatViewPermission, null);
                    }
                }
                return null;
            }
        });
        Object.defineProperty(this, "onSendLocationClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        event: "getLocationCoords",
                    }));
                }
                else {
                    return new Promise((resolve) => {
                        const successCallback = ({ coords }) => {
                            this.props.store.activeChatStore.setSendLocationCoords({
                                latitude: coords.latitude,
                                longitude: coords.longitude,
                            });
                            resolve();
                        };
                        const errorCallback = (error) => {
                            message.error('Location access denied');
                            console.error(error);
                            resolve();
                        };
                        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
                    });
                }
            })
        });
        Object.defineProperty(this, "renderPortals", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.props.store.navigation.rightSidebarModule !== RightSidebarModules.Chat) {
                    return null;
                }
                const { chat } = this.props.store.activeChatStore;
                if (!chat) {
                    return null;
                }
                const { isMobile } = this.props;
                return (React.createElement(React.Fragment, null,
                    this.state.extraPane === ChatPaneName.search &&
                        React.createElement(Portal, { name: isMobile ? PortalName.mobileDrawer : PortalName.rightBarContents, waitHost: isMobile }, React.createElement(ChatSearch, { onClose: this.closeExtraPane, onMessageSelected: this.scrollToMessage })),
                    this.state.extraPane === ChatPaneName.media &&
                        React.createElement(Portal, { name: isMobile ? PortalName.mobileDrawer : PortalName.rightBarContents, 
                            // Race condition with mobile drawer
                            waitHost: isMobile },
                            React.createElement(FilesMediaLinks, { chat: chat, onClose: this.closeExtraPane }))));
            }
        });
        Object.defineProperty(this, "renderUserInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { activeChatStore } = this.props.store, { isMobile } = this.props, { chat } = activeChatStore;
                if (!chat) {
                    return null;
                }
                const renderContents = () => {
                    return React.createElement(UserInfo, { activeChat: chat, isMobile: isMobile, onSwitchPane: (pane) => pane ? this.openExtraPane(pane) : this.closeExtraPane() });
                };
                return (React.createElement("div", { className: styles.userInfoWrap }, renderContents()));
            }
        });
        Object.defineProperty(this, "renderLowerBlock", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { store } = this.props;
                const { composeText, defaultTemplateText } = this.state;
                const { activeChatStore, agentsStore } = store;
                const { chat: activeChat, chatAccess, filePreview, theme, defaultTemplate, sendLocationCoords, } = activeChatStore;
                const isWhatsappChannel = ((_a = activeChat === null || activeChat === void 0 ? void 0 : activeChat.channelInfo) === null || _a === void 0 ? void 0 : _a.name) === 'whatsapp';
                const disabledMessage = (chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.send) ? null : 'noChatSendPermission';
                const showQuickReplies = !filePreview && !sendLocationCoords;
                const blockToShow = this.inputBlockToShow();
                if (!activeChat) {
                    return null;
                }
                return (React.createElement("div", { className: styles.lowerBlock },
                    !!activeChat.replyAction && React.createElement(ReplyPreview, null),
                    blockToShow === INPUT_BLOCK.ACCEPT && (chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.manage) && (React.createElement(AcceptChatBlock, { activeChat: activeChat })),
                    blockToShow === INPUT_BLOCK.SESSION_OVER && (React.createElement(ChatInfoBlock, { titleKey: activeChatStore.sessionIsOverMessage, components: { bold: React.createElement("strong", null), lock: React.createElement(FcLock, { className: "lock-icon" }) } })),
                    blockToShow === INPUT_BLOCK.MESSENGER_PRIVATE_REPLY && (React.createElement(ChatInfoBlock, { titleKey: "messengerPrivateReply" })),
                    blockToShow === INPUT_BLOCK.SESSION_OVER &&
                        defaultTemplate &&
                        isWhatsappChannel &&
                        (chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.send) && (React.createElement(DefaultTemplateReplyBar, { value: defaultTemplateText, onChange: (value) => this.setState({ defaultTemplateText: value }) })),
                    blockToShow === INPUT_BLOCK.COMPOSE && (React.createElement(Compose, { composeText: composeText, onChange: this.onComposeTextChange, onFilesDrop: this.onFilesDrop, sendTextMessageCB: this.sendTextMessage, activeChat: activeChat, onQuickReplySelected: this.onQuickReplySelected, onSendLocationClick: this.onSendLocationClick, quickReplyText: this.state.quickReply, currentChatId: this.state.currentChatId, theme: theme, disabled: !!disabledMessage, disabledMessage: disabledMessage, setAudioBarOpen: this.setAudioBarOpen, beforeUpload: this.beforeUpload })),
                    blockToShow === INPUT_BLOCK.BLOCKED && React.createElement(BlockedChatBar, null),
                    blockToShow === INPUT_BLOCK.AUDIO_RECORD && (React.createElement(AudioRecordBar, { onSendAudioMessage: this.sendAudioMessage, onClose: () => this.setAudioBarOpen(false) })),
                    showQuickReplies && agentsStore.hasPermissions([Permissions.Permission.quick_replies_view]) && (React.createElement(QuickReplies, { onQuickReplySelected: this.onQuickReplySelected }))));
            }
        });
        Object.defineProperty(this, "openExtraPane", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pane) => {
                this.props.store.navigation.setRightSidebarModule(RightSidebarModules.Chat);
                this.setState({ extraPane: pane });
            }
        });
        Object.defineProperty(this, "closeExtraPane", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.props.store.navigation.setRightSidebarModule(RightSidebarModules.CRM);
                this.setState({ extraPane: undefined });
            }
        });
        this.state = {
            quickReply: "",
            composeText: "",
            defaultTemplateText: "",
            currentChatId: "",
            onBottom: false,
            barToDisplay: INPUT_BLOCK.ACCEPT,
            audioBarOpen: false,
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { activeChatStore } = this.props.store;
        if (activeChatStore.chat && this.state.currentChatId !== activeChatStore.chat._id) {
            this.resetAndUpdateChatWindow(activeChatStore.chat);
        }
        if (prevState.barToDisplay !== this.inputBlockToShow()) {
            const newBar = this.inputBlockToShow();
            if (newBar) {
                this.setState({ barToDisplay: newBar });
            }
        }
    }
    render() {
        const { isMobile, store } = this.props;
        const { composeText } = this.state;
        const { activeChatStore, channels } = store;
        const { chat: activeChat, activeReplyMessage, sendLocationMessage, sendLocationCoords, setSendLocationCoords, } = activeChatStore;
        const { hasFeatures } = channels;
        const blockToShow = this.inputBlockToShow();
        const allowPaste = hasFeatures([MessagingChannelFeature.attachments]) && blockToShow === INPUT_BLOCK.COMPOSE;
        const showChatList = !isMobile || activeChat === null;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classnames(styles.messenger, { [styles.chatOpen]: activeChat !== null }) },
                showChatList && (React.createElement("div", { className: classnames(styles.scrollable, styles.sidebar) },
                    React.createElement(ErrorBoundary, null,
                        React.createElement(ChatsList, null)))),
                React.createElement("div", { style: { backgroundImage: this.handleBackgrounds() }, className: classnames(styles.scrollable, styles.content), onPaste: (e) => this.handlePaste(e, allowPaste) },
                    this.renderUserInfo(),
                    this.renderHelperScreens(),
                    React.createElement(DragAndDrop, { handleDrop: this.onFilesDrop, active: this.inputBlockToShow() === INPUT_BLOCK.COMPOSE, isReplyOpen: !!activeReplyMessage },
                        !!activeChat && (React.createElement(ErrorBoundary, null,
                            React.createElement(MessagesList, { reference: (messagesList) => (this.messagesList = messagesList), onBottomStateChange: (onBottom) => this.setState({ onBottom }) }))),
                        !this.state.onBottom && activeChat && (React.createElement("div", { className: styles.scrollToUnreadAnchor },
                            React.createElement(ScrollToUnreadButton, { handleClick: activeChat.unreadCount > 0 ? this.scrollToUnread : this.scrollToBottom, unreadCount: activeChat.unreadCount }))),
                        sendLocationCoords && (React.createElement(SendLocationWindow, { coords: sendLocationCoords, onCancel: () => setSendLocationCoords(null), onSend: () => {
                                sendLocationMessage(sendLocationCoords);
                                setSendLocationCoords(null);
                            } })),
                        React.createElement(FilePreview, { activeChat: activeChat, composeProps: { composeText, onChange: this.onComposeTextChange } })),
                    this.renderLowerBlock())),
            this.renderPortals()));
    }
}
export default withIsMobile(withStore(observer(Messenger)));
